import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout/Layout'
import { SliceZone } from '@prismicio/react';
import { components } from '../components/Slices';
import Section from '../components/Section/Section';
import PageHeadlineContent from "../components/PageHeadlineContent/PageHeadlineContent";

const AboutUsPage = ({ data, location }) => {

  const pageContent = data.allPrismicPage.edges[0].node.data;

  
  return (
    <Layout
      footerImage={pageContent.footer_image.localFile}
      location={location}
      headerImage={pageContent.header_image.localFile}
      alt={pageContent.header_image.alt}
      title={pageContent.page_title.text}
      >
      <Section>
            <PageHeadlineContent
                textAlign="center" 
                sectionHeadline={pageContent.page_title}
                sectionText={pageContent.sub_heading.richText}
            />
        </Section>
        <SliceZone 
          slices={pageContent.body}
          components={components}
        />

    </Layout>
  )
}

export const query = graphql`
query {
  allPrismicPage(filter: {uid: {eq: "our-services"}}) {
    edges {
      node {
        id
        uid
        data {
          body {
            ... on PrismicPageDataBodyFullWidthImage {
              id
              slice_type
              primary {
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
                    }
                  }
                }
              }
            }
            ... on PrismicPageDataBodyOurPhilosophySection {
              id
              slice_type
              items {
                statement {
                  richText
                }
              }
              primary {
                philosophy_title {
                  text
                }
                philosophy_subtitle {
                  richText
                }
              }
            }
            ... on PrismicPageDataBodyOurPartners {
              id
              slice_type
              items {
                partner_link {
                  url
                }
                partner_logo {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
                    }
                  }
                }
              }
              primary {
                partners_title {
                  text
                }
                partners_subtitle {
                  richText
                }
              }
            }
            ... on PrismicPageDataBodyResourcesSection {
              id
              slice_type
              primary {
                section_title {
                  text
                  html
                }
                section_subtitle {
                  text
                }
                section_content {
                  richText
                }
              }
              items {
                title
                image {
                  alt
                  localFile {
                    publicURL
                  }
                }
                description {
                  richText
                }
              }
            }
            ... on PrismicPageDataBodyRichTextSection {
              id
              slice_type
              primary {
                section_titile
                section_subtitle {
                  richText
                }
                section_content {
                  richText
                }
              }
            }
            ... on PrismicPageDataBodyOurProcess {
              id
              slice_type
              primary {
                section_title {
                  text
                }
                section_text {
                  richText
                }
              }
              items {
                step_color
                process_step {
                  text
                }
              }
            }
            ... on PrismicPageDataBodyFeaturesBenefits {
              id
              slice_type
              primary {
                section_title {
                  text
                }
                features {
                  richText
                }
                benefits {
                  richText
                }
              }
            }
          }
          footer_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
              }
            }
          }
          header_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
              }
            }
          }
          page_title
          sub_heading {
            richText
          }
        }
      }
    }
  }
}
`

export default AboutUsPage

export const Head = ({ data }) => {
  const pageTitle = data.allPrismicPage.edges[0].node.data.page_title
  return (
    <title>{`Cloud 9 Builders | ${pageTitle}`}</title>
  )
}
